<template>
  <div style="min-height: inherit">
    <div class="container m-wrapper">
      <Header title="會員資料" backto="/" />
      <!-- <div>
        <span>會員身份</span>
        <div v-if="!profile.license || remainDays < 0" class="grid mt-2">
          <div class="member_button" @click="introModalShow = true">開通會員</div>
          <div class="license_button" @click="$router.push('/plan/add')">輸入授權序號</div>
        </div>
        <div v-else class="d-flex align-items-center justify-content-between my-4">
          <div>
            <div class="m-licenseName">
              {{ profile.license.name }}
            </div>
          </div>
          <div class="licenseListButton clickable" @click="planModalShow = true">查看方案</div>
        </div>
      </div> -->
      <div class="row m-0">
        <ListItemView
          class="col-lg-6"
          :des="'姓名'"
          :value="profile.lastName + ' ' + profile.firstName"
        />
        <ListItemView
          class="col-lg-6"
          :des="'手機號碼'"
          :value="'+' + profile.mobileCountryCode + ' ' + profile.mobile"
          :status="profile.mobile ? '已驗證' : '未驗證'"
        />
        <ListItemView class="col-lg-6" :des="'身分證字號'" :value="profile.nationalId" />
        <ListItemView class="col-lg-6" :des="'車牌'" :value="profile.plateNumber" />
        <ListItemView
          class="col-lg-6"
          :des="'生日'"
          :value="format(parseISO(profile.birthday), 'yyyy/MM/dd')"
        />
        <ListItemView
          class="col-lg-6"
          :des="'地址'"
          :value="profile.city + profile.area + profile.address"
        />
        <ListItemView class="col-lg-6" :des="'Email'" :value="profile.email || '無資料'" />
      </div>
      <div class="row m-0">
        <div class="col p-3 text-center"><a href="#" @click="this.requestLogout">登出帳號</a></div>
      </div>
    </div>
    <IntroModal v-if="introModalShow" :onClose="closeIntroModal" />
    <PlanModal v-if="planModalShow" :onClose="closePlanModal" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { parseISO, format, differenceInDays } from 'date-fns';
import { auth } from '@/mixins';
import Header from '@/components/Header.vue';
import ListItemView from '@/components/ListItemView.vue';
import IntroModal from '@/components/IntroModal.vue';
import PlanModal from '@/components/PlanModal.vue';

export default {
  mixins: [auth],
  data() {
    return {
      introModalShow: false,
      planModalShow: false,
    };
  },
  computed: {
    ...mapState({
      profile: (state) => state.auth.profile,
      remainDays() {
        return differenceInDays(parseISO(this.profile.license.endingTime), new Date());
      },
    }),
  },
  components: {
    Header,
    ListItemView,
    IntroModal,
    PlanModal,
  },
  methods: {
    parseISO,
    format,
    differenceInDays,
    closeIntroModal() {
      this.introModalShow = false;
    },
    closePlanModal() {
      this.planModalShow = false;
    },
  },
};
</script>

<style>
.m-wrapper {
  padding-bottom: 70px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

.member_button {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #404040 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  cursor: pointer;
}

.license_button {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #b49d5f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  cursor: pointer;
}

.m-licenseName {
  color: #ffffff;
  font-size: 17px;
}

.licenseRange {
  color: #6e6e6e;
  font-size: 12px;
}

.licenseListButton {
  color: #b49d5f;
  font-size: 12px;
}
</style>
