<template>
  <div class="listItem p-2">
    <small class="des"
      >{{ des
      }}<span class="ml-2 badge badge-pill badge-success" v-show="status">{{ status }}</span></small
    >
    <div class="title">{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: ['des', 'value', 'status'],
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped="true">
@import '@/assets/lessjs/variables.less';

.listItem {
  color: white;
  border-bottom: 1px solid @dark-grey;
}
.des {
  color: grey;
}
</style>
